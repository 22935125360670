<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your HNC account</p>
                  <CInput
                    placeholder="Your Email"
                    autocomplete="email"
                    v-model="form.email"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <!-- <p class="text-red-500" v-if="errors.email">
                    <small>
                      {{ errors.email[0] }}
                    </small>
                  </p> -->
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="form.password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <!-- <p class="text-red-500" v-if="errors.password">
                    <small>
                      {{ errors.password[0] }}
                    </small>
                  </p> -->
                  <CRow>
                    <CCol col="6" class="text-left">
                      <!-- <LoadingButton
                        @click="submit_login"
                        :block="'block'"
                        :loading="loading"
                        :color="'primary'"
                        :text="'Login'"
                      ></LoadingButton> -->
                      <CButton
                        @click="submit_login"
                        color="primary"
                        class="px-4"
                        block
                        >Login<span v-if="loading" class="ml-2"
                          ><img class="gif" src="/img/loading-sm.gif"/></span
                      ></CButton>
                    </CCol>

                    <CCol col="6" class="text-right">
                      <CButton color="link" to="/a/forgot" class="px-0"
                        >Forgot password?</CButton
                      >
                      <!-- <CButton color="link" to="/a/register" class="ml-4 px-0"
                        >Register</CButton
                      > -->
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import LoadingButton from "../components/LoadingButton.vue";

export default {
  name: "Login",
  components: { LoadingButton },
  data() {
    return {
      loading: false,
      form: {
        email: "",
        password: "",
      },
      errors: { email: [], password: [] },
    };
  },
  methods: {
    submit_login() {
      const axios = require("axios");
      console.log('sending login request');
      let vm = this;
      vm.loading = true;
      axios
        .post("/login", this.form)
        .then((res) => {
          console.log(res);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("login_time", Math.floor(Date.now() / 1000));
          vm.loading = false;
          // this.$router.push("/dashboard");
          window.location.replace("/dashboard");
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          vm.loading = false;
          console.log("loading: ", vm.loading);
          vm.errors = err.response.data.errors;
          console.log(vm.errors);
          Swal.fire("Oops...", err.response.data.message, "error");
        });
    },
  },
};
</script>

<style scoped>
.gif {
  max-height: 20px;
  max-width: 20px;
}
</style>
