<template>
  <CButton :color="color" class="px-4" :block="block"
    >{{ text
    }}<span v-if="loading" class="ml-2"
      ><img class="gif" src="/img/loading-sm.gif"
    /></span>
  </CButton>
</template>

<script>
export default {
  name: "LoadingButton",
  props: ["color", "text", "block", "loading"],
};
</script>

<style></style>
